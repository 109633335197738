<template>
  <v-dialog justify="center" :value="value" persistent max-width="300">
    <v-card color=#fafafa>
      <section class="ma-0 pa-6">
        <v-row justify="center">
          <v-icon x-large color="#2AACE3">mdi-help-rhombus-outline</v-icon>
        </v-row>
        <v-row justify="center">
          <h3>
            {{ msgText }}
          </h3>
        </v-row>
        <v-row justify="center">
          <v-btn height="40" class="elevation-1" @click.stop="$emit('update:value', false)">
            No
          </v-btn>
          <v-btn color="#0c67a5" height="40" class="white--text ml-2"
            @click="confirmAction">Yes
          </v-btn>
        </v-row>
      </section>
    </v-card>
  </v-dialog>
</template>

<script>
import { notification } from '@/mixins/notification'

export default {
  name: 'ConfirmClosingAction',

  mixins: [notification],

  props: ['value', 'period', 'year', 'selectedStep', 'getClosingSteps', 'action'],

  data () {
    return {}
  },

  computed: {
    msgText () {
      switch (this.action) {
        case 'restart':
          return `Restart step ${this.selectedStep.step_description}?`
        case 'submit':
          return "Start closing for period?"
        case 'post':
          return "Post recaps for period?"
      }
      return "Confirm"
    }
  },

  methods: {
    async confirmAction () {

      switch (this.action) {
        case 'restart':
          this.restartStep(this.selectedStep.step_id, this.selectedStep.stage)
          return
        case 'submit':
          this.submitClosing()
          return
        case 'post':
          this.postClosing()
          return
      }
    },

    submitClosing() {
      this.$closing.submitClosing(this.period, this.year)
        .then(() => {
          this.notify('success', `Closing steps submitted`)
          this.$emit('update:value', false)
          this.getClosingSteps()
        })
    },

    restartStep(step_id, stage) {
      this.$closing.restartStep(this.period, this.year, step_id, stage)
        .then(() => {
          this.notify('success', `Step ${this.selectedStep.step_description} restarted`)
          this.$emit('update:value', false)
          this.getClosingSteps()
        })
    },

    postClosing() {
      this.$closing.postClosing(this.period, this.year)
        .then(() => {
          this.notify('success', `Recap posting submitted`)
          this.$emit('update:value', false)
          this.getClosingSteps()
        })
    }
  }
}
</script>
